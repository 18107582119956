import $road, { userStates } from "agri_base";
import React, { lazy, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Route, Routes, useLocation } from "react-router";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import ErrorPage from "./pages/Error";
import { Loading } from "./styles/Global";

const Login = lazy(() => import("./pages/Login"));
const Module = lazy(() => import("./pages/Module"));
const Control = lazy(() => import("./app/Control/App"));
const Data = lazy(() => import("./app/Data/App"));
const Source = lazy(() => import("./app/Source/App"));
const Video = lazy(() => import("./app/Video/App"));
const H5 = lazy(() => import("./app/H5/App"));
const Console = lazy(() => import("./app/Console/App"));

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  // 用户信息
  const user = useRecoilValue(userStates.userState);

  const { pathname } = useLocation();

  const HostQ = useQuery(
    ["host-query", window.location.host, i18n.language],
    () => $road.CMS.Inc.Assign({ host: window.location.host }),
    {
      onSuccess: (data) => {
        const link = document.head.querySelector("link");
        if (link) {
          link.href = data?.logo || "";
        }
        if (pathname === "/") {
        }
        if (data.language === "en") {
          i18n.changeLanguage("en");
        } else {
          if (!i18n.language) {
            i18n.changeLanguage("zh");
          }
        }
        switch (pathname) {
          case "/":
            document.title = `${data?.platform_title}`;
            break;
          case "/source":
            document.title = `${t("module.Source")}-${data?.platform_title}`;
            break;
          case "/data":
            document.title = `${t("module.Data")}-${data?.platform_title}`;
            break;
          case "/control":
            document.title = `${t("module.Control")}-${data?.platform_title}`;
            break;
          case "/video":
            document.title = `${t("module.Video")}-${data?.platform_title}`;
            break;
        }
      },
    }
  );

  // 是否登录，渲染不同页面
  const BuildPage = useMemo(() => {
    if (user) {
      return <Module />;
    }
    return <Login loading={HostQ.isLoading} indexIncInfo={HostQ.data} />;
  }, [HostQ, user]);

  return (
    <Suspense fallback={<Loading>Loading...</Loading>}>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/">
          <Route index element={BuildPage} />
          <Route path="data/*" element={<Data />} />
          <Route path="control/*" element={<Control />} />
          <Route path="source/*" element={<Source />} />
          <Route path="video/*" element={<Video />} />
          <Route path="h5/*" element={<H5 />} />
          <Route path="console/*" element={<Console />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
