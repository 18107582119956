import styled from "styled-components";

interface ContainerProps {
  background: string;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: url(${(props: ContainerProps) => props.background}) no-repeat
    center;
  background-size: cover;
`;

export const HomeLink = styled.a`
  color: #1890ff;
  text-decoration: underline !important;
`;

export const Panel = styled.div`
  width: 300px;
  height: 250px;
  background: #ffffffb3;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: 1px 1px 10px;
`;

export const Title = styled.h2`
  margin: 0;
`;

export const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 75px;
  border: 3px solid #1890ff;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #1890ff;
  font-size: xx-large;
`;
