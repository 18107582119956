import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import loginBG from "../assets/login/bg-login.png";
import { Circle, Container, HomeLink, Panel, Title } from "../styles/Error";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const [number, setNumber] = useState(5);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (number === 0) {
        navigate("/");
      }
      setNumber(number - 1);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [navigate, number]);

  return (
    <Container background={loginBG}>
      <Panel>
        <Title>Error Page</Title>
        <Title>错误页面</Title>
        <Circle>{number}</Circle>
        <Title>
          倒计时结束回到<HomeLink href="/">主页</HomeLink>
        </Title>
      </Panel>
    </Container>
  );
};

export default ErrorPage;
