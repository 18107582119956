// 登录模块
export var LoginEnums;
(function (LoginEnums) {
    // input 类型
    let Input;
    (function (Input) {
        Input[Input["Account"] = 0] = "Account";
        Input[Input["Password"] = 1] = "Password";
    })(Input = LoginEnums.Input || (LoginEnums.Input = {}));
    // 颜色类型
    let Color;
    (function (Color) {
        Color["White"] = "#ffffff";
        Color["Black"] = "#000000";
    })(Color = LoginEnums.Color || (LoginEnums.Color = {}));
})(LoginEnums || (LoginEnums = {}));
// 溯源系统
export var SourceEnums;
(function (SourceEnums) {
    let Action;
    (function (Action) {
        Action[Action["Other"] = 0] = "Other";
        Action[Action["Watering"] = 1] = "Watering";
        Action[Action["Produce"] = 2] = "Produce";
    })(Action = SourceEnums.Action || (SourceEnums.Action = {}));
    let Plant;
    (function (Plant) {
        Plant[Plant["Idle"] = 0] = "Idle";
        Plant[Plant["Earth"] = 10] = "Earth";
        Plant[Plant["Grow"] = 20] = "Grow";
        Plant[Plant["Mature"] = 30] = "Mature";
        Plant[Plant["Reward"] = 40] = "Reward";
    })(Plant = SourceEnums.Plant || (SourceEnums.Plant = {}));
    let Format;
    (function (Format) {
        Format["Time"] = "LT";
        Format["Times"] = "LTS";
        Format["Date"] = "ll";
        Format["Dates"] = "LL";
        Format["DateTime"] = "lll";
        Format["DateTimes"] = "LLL";
        Format["DateWeek"] = "llll";
        Format["DateWeeks"] = "LLLL";
        Format["TimeStamp"] = "X";
    })(Format = SourceEnums.Format || (SourceEnums.Format = {}));
})(SourceEnums || (SourceEnums = {}));
//Data系列
export var DataEnums;
(function (DataEnums) {
    let SegmentedStatus;
    (function (SegmentedStatus) {
        SegmentedStatus["FWD"] = "forward";
        SegmentedStatus["STOP"] = "stop";
        SegmentedStatus["REV"] = "antiport";
    })(SegmentedStatus = DataEnums.SegmentedStatus || (DataEnums.SegmentedStatus = {}));
    // 农场主页枚举
    let Index;
    (function (Index) {
        Index["CHUCHAO"] = "f-ixuwksvxuipe";
        Index["DAOTIAN"] = "f-qdbjzqqcgxex";
    })(Index = DataEnums.Index || (DataEnums.Index = {}));
    // 日志类别枚举
    let Logs;
    (function (Logs) {
        Logs["WARNING"] = "Warning";
        Logs["OPERATING"] = "Operating";
    })(Logs = DataEnums.Logs || (DataEnums.Logs = {}));
    // 固定页面路径枚举
    let Pages;
    (function (Pages) {
        Pages["INDEX"] = "/data";
        Pages["WARNING"] = "/data/warning";
        Pages["OPERATING"] = "/data/operating";
        Pages["VIDEO"] = "/data/video";
        Pages["Map"] = "/data/map";
    })(Pages = DataEnums.Pages || (DataEnums.Pages = {}));
    // 查询类型枚举
    let Query;
    (function (Query) {
        Query["RECENT"] = "Recent";
        Query["TODAY"] = "Today";
        Query["THREEDAYS"] = "ThreeDays";
        Query["SEVEN"] = "Seven";
        Query["THIRTYDAYS"] = "ThirtyDays";
    })(Query = DataEnums.Query || (DataEnums.Query = {}));
    // 主页展示类型枚举
    let ShowComponentType;
    (function (ShowComponentType) {
        ShowComponentType["RoomInfo"] = "RoomInfo";
        ShowComponentType["DeviceInfo"] = "DeviceInfo";
        ShowComponentType["WARNING"] = "Warning";
        ShowComponentType["OPERATING"] = "Operating";
    })(ShowComponentType = DataEnums.ShowComponentType || (DataEnums.ShowComponentType = {}));
    // 主页组件类型枚举
    let Component;
    (function (Component) {
        Component["INFO"] = "Info";
        Component["SWITCH"] = "Switch";
        Component["VIDEO"] = "Video";
        Component["GPS"] = "GPS";
        Component["SEGMENTED"] = "Segmented";
        Component["WARNING"] = "Warning";
        Component["OPERATING"] = "Operating";
    })(Component = DataEnums.Component || (DataEnums.Component = {}));
    // 侧边栏类型枚举
    let Aside;
    (function (Aside) {
        Aside["INDEX"] = "Index";
        Aside["WARNING"] = "Warning";
        Aside["OPERATING"] = "Operating";
        Aside["VIDEO"] = "Video";
        Aside["MAP"] = "Map";
    })(Aside = DataEnums.Aside || (DataEnums.Aside = {}));
})(DataEnums || (DataEnums = {}));
// H5
export var H5Enums;
(function (H5Enums) {
    let PlantEnum;
    (function (PlantEnum) {
        PlantEnum[PlantEnum["Idle"] = 0] = "Idle";
        PlantEnum[PlantEnum["Earth"] = 10] = "Earth";
        PlantEnum[PlantEnum["Grow"] = 20] = "Grow";
        PlantEnum[PlantEnum["Mature"] = 30] = "Mature";
        PlantEnum[PlantEnum["Reward"] = 40] = "Reward";
    })(PlantEnum = H5Enums.PlantEnum || (H5Enums.PlantEnum = {}));
    let FormatEnum;
    (function (FormatEnum) {
        FormatEnum["Time"] = "LT";
        FormatEnum["Times"] = "LTS";
        FormatEnum["Date"] = "ll";
        FormatEnum["Dates"] = "LL";
        FormatEnum["DateTime"] = "lll";
        FormatEnum["DateTimes"] = "LLL";
        FormatEnum["DateWeek"] = "llll";
        FormatEnum["DateWeeks"] = "LLLL";
        FormatEnum["oDate"] = "MM\u6708DD\u65E5";
    })(FormatEnum = H5Enums.FormatEnum || (H5Enums.FormatEnum = {}));
})(H5Enums || (H5Enums = {}));
