import { atom, DefaultValue, selector } from "recoil";
// 用户信息保存本地localStorage
const localStorageEffect = (key) => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (!!savedValue && savedValue !== "undefined") {
        try {
            const localObj = JSON.parse(savedValue);
            setSelf(localObj);
        }
        catch (_a) {
            setSelf(null);
        }
    }
    onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        }
        else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};
// 用户
export var userStates;
(function (userStates) {
    // 用户信息
    userStates.userState = atom({
        key: "userState",
        default: null,
        effects_UNSTABLE: [localStorageEffect("userState")],
    });
    // 获取用户session
    userStates.sessionSelector = selector({
        key: "userSessionState",
        get: ({ get }) => {
            const user = get(userStates.userState);
            if (user === null || user === void 0 ? void 0 : user.session) {
                return user.session;
            }
            return null;
        },
    });
    // 获取用户模块
    userStates.modulesSelector = selector({
        key: "userModulesState",
        get: ({ get }) => {
            const user = get(userStates.userState);
            if (user === null || user === void 0 ? void 0 : user.modules) {
                return user.modules;
            }
            return [];
        },
    });
})(userStates || (userStates = {}));
// 农场
export var farmStates;
(function (farmStates) {
    farmStates.listState = atom({
        key: "farmListState",
        default: [],
    });
    farmStates.selState = atom({
        key: "farmSelState",
        default: null,
        effects_UNSTABLE: [localStorageEffect("farmSelState")],
    });
    farmStates.selAgriIdSelector = selector({
        key: "selAgriIdSelector",
        get({ get }) {
            const sel = get(farmStates.selState);
            if (sel) {
                return sel.farm.agri_id;
            }
            return null;
        },
    });
    farmStates.notSelSelector = selector({
        key: "farmNotSelSelector",
        get: ({ get }) => {
            const sel = get(farmStates.selState);
            const list = get(farmStates.listState);
            return list.filter((farm) => farm.farm.pk !== (sel === null || sel === void 0 ? void 0 : sel.farm.pk));
        },
    });
})(farmStates || (farmStates = {}));
// 设备
export var deviceStates;
(function (deviceStates) {
    deviceStates.listState = atom({
        key: "deviceState",
        default: [],
    });
    deviceStates.mqttListState = atom({
        key: "deviceMqttState",
        default: {},
    });
    deviceStates.mqttSelector = selector({
        key: "deviceMqttSelector",
        get: ({ get }) => {
            const mqttList = get(deviceStates.mqttListState);
            return mqttList;
        },
        set: ({ get, set }, newValue) => {
            const mqttList = get(deviceStates.mqttListState);
            set(deviceStates.mqttListState, Object.assign(Object.assign({}, mqttList), newValue));
        },
    });
    let switchSelector;
    (function (switchSelector) {
        switchSelector.roomNameList = selector({
            key: "switch-roomNameList",
            get: ({ get }) => {
                const deviceList = get(deviceStates.listState);
                return [...new Set(deviceList.map((device) => device.room_name))];
            },
        });
        switchSelector.listSelector = selector({
            key: "switch-switchList",
            get: ({ get }) => {
                const deviceList = get(deviceStates.listState);
                if (deviceList.length === 0) {
                    return [];
                }
                return deviceList.filter((device) => device.the_type === 200);
            },
        });
    })(switchSelector = deviceStates.switchSelector || (deviceStates.switchSelector = {}));
    let segmentedSelector;
    (function (segmentedSelector) {
        segmentedSelector.roomNameList = selector({
            key: "segmented-roomNameList",
            get: ({ get }) => {
                const deviceList = get(deviceStates.listState);
                return [...new Set(deviceList.map((device) => device.room_name))];
            },
        });
        segmentedSelector.listSelector = selector({
            key: "segmented-segmentedList",
            get: ({ get }) => {
                const deviceList = get(deviceStates.listState);
                if (deviceList.length === 0) {
                    return [];
                }
                return deviceList.filter((device) => device.the_type === 210);
            },
        });
    })(segmentedSelector = deviceStates.segmentedSelector || (deviceStates.segmentedSelector = {}));
    let videoSelector;
    (function (videoSelector) {
        videoSelector.listSelector = selector({
            key: "video-videoList",
            get: ({ get }) => {
                const deviceList = get(deviceStates.listState);
                if (deviceList.length === 0) {
                    return [];
                }
                return deviceList.filter((device) => device.the_type === 100);
            },
        });
    })(videoSelector = deviceStates.videoSelector || (deviceStates.videoSelector = {}));
    let stickedSelector;
    (function (stickedSelector) {
        stickedSelector.listSelector = selector({
            key: "sticked-selector",
            get: ({ get }) => {
                const deviceList = get(deviceStates.listState);
                if (deviceList.length === 0) {
                    return [];
                }
                return deviceList.filter((device) => device.sticked === true);
            },
        });
    })(stickedSelector = deviceStates.stickedSelector || (deviceStates.stickedSelector = {}));
})(deviceStates || (deviceStates = {}));
// 蒙层
export var maskStates;
(function (maskStates) {
    maskStates.maskState = atom({
        key: "maskState",
        default: false,
    });
})(maskStates || (maskStates = {}));
// 密码
export var passwordStates;
(function (passwordStates) {
    passwordStates.passwordState = atom({
        key: "passwordState",
        default: {
            agri_id: "",
            password: "",
        },
    });
    passwordStates.passwordSelector = selector({
        key: "passwordSelector",
        get: ({ get }) => {
            return get(passwordStates.passwordState);
        },
        set: ({ get, set }, newValue) => {
            const password = get(passwordStates.passwordState);
            set(passwordStates.passwordState, Object.assign(Object.assign({}, password), newValue));
        },
    });
})(passwordStates || (passwordStates = {}));
// UI
export var uiStates;
(function (uiStates) {
    uiStates.farmSelectPanelState = atom({
        key: "farmSelectPanelState",
        default: false,
    });
    uiStates.asideState = atom({
        key: "asideState",
        default: false,
    });
    uiStates.settingState = atom({
        key: "settingState",
        default: false,
    });
    uiStates.componentState = atom({
        key: "componentState",
        default: false,
    });
    uiStates.uiSelector = selector({
        key: "uiSelector",
        get: ({ get }) => true,
        set({ set }, newValue) {
            set(uiStates.farmSelectPanelState, newValue);
            set(uiStates.asideState, newValue);
            set(uiStates.settingState, newValue);
            set(uiStates.componentState, newValue);
        },
    });
})(uiStates || (uiStates = {}));
// 缩放
export var zoomStates;
(function (zoomStates) {
    zoomStates.zoomState = atom({
        key: "zoomState",
        default: {
            width: 1,
            height: 1,
        },
    });
    zoomStates.sizeSelector = selector({
        key: "sizeSelector",
        get: ({ get }) => {
            const zoom = get(zoomStates.zoomState);
            const size = {
                headHeight: `${72 * zoom.width}px`,
                headSideHeight: `${66 * zoom.width}px`,
                mainHeight: `${(1080 - 72) * zoom.width}px`,
                mainWidth: zoom.width > zoom.height
                    ? `${1920 * zoom.height}px`
                    : `${1920 * zoom.width}px`,
            };
            return size;
        },
    });
})(zoomStates || (zoomStates = {}));
export var ComponentStates;
(function (ComponentStates) {
    ComponentStates.comState = atom({
        key: "comState",
        default: [],
        effects_UNSTABLE: [localStorageEffect("comState")],
    });
    ComponentStates.comSelector = selector({
        key: "comSelector",
        get: ({ get }) => {
            const farms = get(ComponentStates.comState);
            const farmSel = get(farmStates.selState);
            const theFarm = farms === null || farms === void 0 ? void 0 : farms.find((farm) => farm.farmPk === (farmSel === null || farmSel === void 0 ? void 0 : farmSel.farm.pk));
            if (theFarm) {
                return theFarm === null || theFarm === void 0 ? void 0 : theFarm.kv;
            }
            else {
                return {};
            }
        },
        set: ({ get, set }, newValue) => {
            const farms = get(ComponentStates.comState);
            const farmSel = get(farmStates.selState);
            if (!!farms && farmSel) {
                const otherFarm = farms.filter((farm) => farm.farmPk !== farmSel.farm.pk);
                const theFarm = farms.find((farm) => farm.farmPk === farmSel.farm.pk);
                if (theFarm) {
                    const newFarm = {
                        farmPk: theFarm.farmPk,
                        kv: Object.assign(Object.assign({}, theFarm.kv), newValue),
                    };
                    if (otherFarm) {
                        set(ComponentStates.comState, [...otherFarm, newFarm]);
                    }
                    else {
                        set(ComponentStates.comState, [newFarm]);
                    }
                }
                else {
                    const newFarm = {
                        farmPk: farmSel.farm.pk,
                        kv: Object.assign({}, newValue),
                    };
                    if (otherFarm) {
                        set(ComponentStates.comState, [...otherFarm, newFarm]);
                    }
                    else {
                        set(ComponentStates.comState, [newFarm]);
                    }
                }
            }
        },
    });
    ComponentStates.line1Selector = selector({
        key: "line1Selector",
        get: ({ get }) => {
            const farms = get(ComponentStates.comState);
            if (farms) {
                const farmSel = get(farmStates.selState);
                if (farmSel) {
                    const theFarm = farms.find((farm) => farm.farmPk === farmSel.farm.pk);
                    if (theFarm) {
                        const coms = theFarm.kv;
                        if (coms) {
                            const com0 = coms[0];
                            const com1 = coms[1];
                            return { 0: com0, 1: com1 };
                        }
                    }
                }
            }
            return { 0: null, 1: null };
        },
    });
    ComponentStates.line2Selector = selector({
        key: "line2Selector",
        get: ({ get }) => {
            const farms = get(ComponentStates.comState);
            if (farms) {
                const farmSel = get(farmStates.selState);
                if (farmSel) {
                    const theFarm = farms.find((farm) => farm.farmPk === farmSel.farm.pk);
                    if (theFarm) {
                        const coms = theFarm.kv;
                        if (coms) {
                            const com2 = coms[2];
                            const com3 = coms[3];
                            return { 2: com2, 3: com3 };
                        }
                    }
                }
            }
            return { 2: null, 3: null };
        },
    });
    ComponentStates.line3Selector = selector({
        key: "line3Selector",
        get: ({ get }) => {
            const farms = get(ComponentStates.comState);
            if (farms) {
                const farmSel = get(farmStates.selState);
                if (farmSel) {
                    const theFarm = farms.find((farm) => farm.farmPk === farmSel.farm.pk);
                    if (theFarm) {
                        const coms = theFarm.kv;
                        if (coms) {
                            const com4 = coms[4];
                            const com5 = coms[5];
                            return { 4: com4, 5: com5 };
                        }
                    }
                }
            }
            return { 4: null, 5: null };
        },
    });
})(ComponentStates || (ComponentStates = {}));
// 选择数据组
export var dataGroupStates;
(function (dataGroupStates) {
    dataGroupStates.selDataGroup = atom({
        key: "selDataGroup",
        default: null,
        effects_UNSTABLE: [localStorageEffect("selDataGroup")],
    });
})(dataGroupStates || (dataGroupStates = {}));
