import { axios, Version } from ".";
/**
 * 溯源系统
 */
var Cloud;
(function (Cloud) {
    /**
     * 农产品
     */
    let Plant;
    (function (Plant) {
        /**
         * 获取农产品列表
         */
        Plant.GetList = ({ session, farm_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/`, {
            headers: { session },
        });
        /**
         * 添加农产品
         */
        Plant.Post = ({ session, farm_pk, plant, }) => axios.post(`${Version}/trace/${farm_pk}/plant/`, plant, {
            headers: { session },
        });
        /**
         * 农产品详情
         */
        Plant.GetInfo = ({ session, farm_pk, plant_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/`, {
            headers: { session },
        });
        /**
         * 农产品更新
         */
        Plant.Put = ({ session, farm_pk, plant_pk, plant, }) => axios.put(`${Version}/trace/${farm_pk}/plant/${plant_pk}/`, plant, {
            headers: { session },
        });
        /**
         * 农产品完成
         */
        Plant.Completed = ({ session, farm_pk, plant_pk, plant, }) => axios.put(`${Version}/trace/${farm_pk}/plant/${plant_pk}/`, plant, {
            headers: { session },
        });
        /**
         * 删除农产品
         */
        Plant.Delete = ({ session, farm_pk, plant_pk, }) => axios.delete(`${Version}/trace/${farm_pk}/plant/${plant_pk}/`, {
            headers: { session },
        });
        /**
         * 获取农产品二维码
         */
        Plant.QrCode = ({ session, farm_pk, plant_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/qrcode`, {
            headers: { session },
        });
        /**
         * 农产品阈值
         */
        let Threshold;
        (function (Threshold) {
            /**
             * 获取农产品可以设置的阈值
             */
            Threshold.GetList = ({ session, farm_pk, plant_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/threshold/threshold_devices/`, {
                headers: { session },
            });
            /**
             * 获取农产品设置的阈值
             */
            Threshold.GetSet = ({ session, farm_pk, plant_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/threshold/`, {
                headers: { session },
            });
            /**
             * 添加阈值
             */
            Threshold.Post = ({ session, farm_pk, plant_pk, threshold, }) => axios.post(`${Version}/trace/${farm_pk}/plant/${plant_pk}/threshold/`, threshold, {
                headers: { session },
            });
            /**
             * 删除阈值
             */
            Threshold.Delete = ({ session, farm_pk, plant_pk, the_type, }) => axios.delete(`${Version}/trace/${farm_pk}/plant/${plant_pk}/threshold/${the_type}/`, {
                headers: { session },
            });
        })(Threshold = Plant.Threshold || (Plant.Threshold = {}));
    })(Plant = Cloud.Plant || (Cloud.Plant = {}));
    /**
     * 农产品批次
     */
    let Unit;
    (function (Unit) {
        /**
         * 批次列表
         */
        Unit.GetList = ({ session, farm_pk, plant_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/unit/`, {
            headers: { session },
        });
        /**
         * 添加批次
         */
        Unit.Post = ({ session, farm_pk, plant_pk, unit, }) => axios.post(`${Version}/trace/${farm_pk}/plant/${plant_pk}/unit/`, unit, {
            headers: { session },
        });
        /**
         * 批次详情
         */
        Unit.GetInfo = ({ session, farm_pk, plant_pk, unit_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/unit/${unit_pk}/`, { headers: { session } });
        /**
         * 批次更新
         */
        Unit.Put = ({ session, farm_pk, plant_pk, unit_pk, unit, }) => axios.put(`${Version}/trace/${farm_pk}/plant/${plant_pk}/unit/${unit_pk}/`, unit, { headers: { session } });
        /**
         * 删除
         */
        Unit.Delete = ({ session, farm_pk, plant_pk, unit_pk, }) => axios.delete(`${Version}/trace/${farm_pk}/plant/${plant_pk}/unit/${unit_pk}/`, { headers: { session } });
        /**
         * 获取农产品批次二维码
         */
        Unit.QrCode = ({ session, farm_pk, plant_pk, unit_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/unit/${unit_pk}/qrcode/`, { headers: { session } });
        /**
         * 批量获取一物一码
         */
        Unit.SubQrCode = ({ session, farm_pk, plant_pk, unit_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/unit/${unit_pk}/subqrcode/`, { headers: { session }, responseType: "blob" });
    })(Unit = Cloud.Unit || (Cloud.Unit = {}));
    /**
     * 农产品相册
     */
    let Album;
    (function (Album) {
        /**
         * 获取相册图片列表
         */
        Album.GetList = ({ session, farm_pk, plant_pk, }) => axios.get(`${Version}/trace/${farm_pk}/plant/${plant_pk}/album/`, {
            headers: { session },
        });
        /**
         * 添加相册图片
         */
        Album.Post = ({ session, farm_pk, plant_pk, }) => axios.post(`${Version}/trace/${farm_pk}/plant/${plant_pk}/album/`, {}, { headers: { session } });
        /**
         * 修改图片是否关键
         */
        Album.Put = ({ session, farm_pk, plant_pk, album_pk, album, }) => axios.put(`${Version}/trace/${farm_pk}/plant/${plant_pk}/album/${album_pk}/`, album, { headers: { session } });
        /**
         * 删除图片
         */
        Album.Delete = ({ session, farm_pk, plant_pk, album_pk, }) => axios.delete(`${Version}/trace/${farm_pk}/plant/${plant_pk}/album/${album_pk}/`, {
            headers: { session },
        });
    })(Album = Cloud.Album || (Cloud.Album = {}));
    /**
     * 溯源数据
     */
    let Data;
    (function (Data) {
        /**
         * 添加溯源数据
         */
        Data.Post = ({ session, farm_pk, data, }) => axios.post(`${Version}/trace/data/${farm_pk}/`, data, {
            headers: { session },
        });
        /**
         * 获取溯源数据
         */
        Data.GetList = ({ session, farm_pk, filter, }) => axios.get(`${Version}/trace/data/${farm_pk}/`, {
            params: filter,
            headers: { session },
        });
        /**
         * 删除溯源数据
         */
        Data.Delete = ({ session, farm_pk, filter, }) => axios.delete(`${Version}/trace/data/${farm_pk}/`, {
            data: filter,
            headers: { session },
        });
    })(Data = Cloud.Data || (Cloud.Data = {}));
    /**
     * 常用操作
     */
    let Action;
    (function (Action) {
        /**
         * 获取常用操作列表
         */
        Action.GetList = ({ session, farm_pk, }) => axios.get(`${Version}/trace/${farm_pk}/action/`, {
            headers: { session },
        });
        /**
         * 添加常用操作
         */
        Action.Post = ({ session, farm_pk, action, }) => axios.post(`${Version}/trace/${farm_pk}/action/`, action, {
            headers: { session },
        });
        /**
         * 常用操作详情
         */
        Action.GetInfo = ({ session, farm_pk, action_pk, }) => axios.get(`${Version}/trace/${farm_pk}/action/${action_pk}/`, {
            headers: { session },
        });
        /**
         * 更新常用操作
         */
        Action.Put = ({ session, farm_pk, action_pk, action, }) => axios.put(`${Version}/trace/${farm_pk}/action/${action_pk}/`, action, {
            headers: { session },
        });
        /**
         * 删除常用操作
         */
        Action.Delete = ({ session, farm_pk, action_pk, }) => axios.delete(`${Version}/trace/${farm_pk}/action/${action_pk}/`, {
            headers: { session },
        });
    })(Action = Cloud.Action || (Cloud.Action = {}));
    /**
     * 种植过程
     */
    let Process;
    (function (Process) {
        /**
         * 种植过程列表
         */
        Process.GetList = ({ session, farm_pk, }) => axios.get(`${Version}/trace/${farm_pk}/process/`, {
            headers: { session },
        });
        /**
         * // TODO 未完成
         * 添加种植过程
         */
        Process.Post = () => { };
        /**
         * 获取详情
         */
        Process.GetInfo = ({ session, farm_pk, process_pk, }) => axios.get(`${Version}/trace/${farm_pk}/process/${process_pk}/`, {
            headers: { session },
        });
        /**
         * 删除种植过程
         */
        Process.Delete = ({ session, farm_pk, process_pk, }) => axios.delete(`${Version}/trace/${farm_pk}/process/${process_pk}/`, {
            headers: { session },
        });
    })(Process = Cloud.Process || (Cloud.Process = {}));
})(Cloud || (Cloud = {}));
export default Cloud;
